<template>
  <f7-page class="product-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProductNavigationComponent title="" type="back" searchname="searchwinerylist" @searched="search" />
    </template>
    <div class="title">
      <h1>{{ $t.getTranslation("LBL_WINERY") }}</h1>
    </div>
    <section class="container">
      <div v-for="w in list" :key="'winery_' + w.WineryId" class="winery">
        <div class="winery-texts">
          <h1 class="winery-name">{{ w.WineryName }}</h1>
          <span class="wineryshop-now" @click="go(w.WineryCode)">{{ $t.getTranslation("LBL_VIEW") }}</span>
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, inject, onMounted, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

import { f7 } from "framework7-vue";
// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));

export default defineComponent({
  name: "WineryListPage",
  components: { ProductNavigationComponent },
  props: {},
  setup(props) {
    const list = ref([]);
    const showPreloader = ref(false);
    const $t = inject("$translation");
    const $f7router = f7.views.get("#main-view").router;
    let page = 1;
    let size = 30;
    let lastPage = 1;

    const store = useStore();

    const getData = async () => {
      let ret = await get(`/winery/list?page=${page}&size=${size}`, {
        page: page,
        size: size,
        LanguageCode: $t.getLanguage(),
        mode: props.mode || "",
      });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          list.value.push(item);
        }
        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getData() : false;
    };

    const go = (winerycode) => {
      $f7router.navigate({ name: "productListPage", query: { search: winerycode } });
      return;
    };

    onMounted(() => {
      getData();
    });

    return { list, loadMore, showPreloader, go };
  },
});
</script>

<style scoped>
.product-page {
  padding: 10px;
}
.container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.winery-container {
  width: 190px;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px 0px 10px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  overflow: hidden;
  cursor: pointer;
}
.winery {
  width: 180px;
  height: 180px;
  background-image: url("../../assets/images/winery-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  overflow: hidden;
  cursor: pointer;
  margin: 0px 0px 10px;
  position: relative;
  border-radius: 50%;
}
.winery-texts {
  text-align: center;
  width: 175px;
  word-break: break-all;
}
.winery-name {
  text-transform: uppercase;
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 10px;
}
.wineryshop-now {
  text-transform: uppercase;
  color: white;
  border: 2px solid #fff;
  padding: 0px 25px;
  border-radius: 5px;
}

.fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
</style>
